<template>
  <v-row>
    <v-col>
      <base-card>
        <v-card-title>neues Passwort</v-card-title>
        <v-card-text v-if="!requestSent">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-1"
                label="Passwort eingeben"
                hint="At least 8 characters"
                :counter="8"
                :error-messages="passwordErrors"
                @click:append="show = !show"
              />
              <v-text-field
                v-model="confirmPassword"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Confirm Password"
                :counter="8"
                :error-messages="confirmPasswordErrors"
                @click:append="show = !show"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <h6 class="text--disabled font-weight-medium pl-4" v-else>
          {{ responseMessage }}
        </h6>
        <div class="d-flex flex-wrap" v-if="!requestSent">
          <v-btn
            class="ml-4 mb-4"
            outlined
            color="green"
            small
            @click="setPassword"
          >
            Speichern
          </v-btn>
        </div>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import { getUserID, post } from '../../../worker/worker-api'
  import { validationMixin } from 'vuelidate';
  import { required, minLength, sameAs } from 'vuelidate/lib/validators';
  import { mapActions } from 'vuex';

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Passwort ändern',
    },
    mixins: [validationMixin],
    data: () => ({
      show: false,
      password: '',
      confirmPassword: '',
      requestSent: false,
      responseMessage: '',
      errorMessages: '',
      formHasErrors: false,
    }),
    computed: {
      passwordErrors() {
        const errors = []
        if (!this.$v.password.$dirty) {
          this.messageSnackbar = 'Password must be required';
          return errors
        }
        !this.$v.password.required && errors.push('Password is required')
        !this.$v.password.minLength && errors.push('Password must have minimum 10 characters')
        return errors
      },
      confirmPasswordErrors() {
        const errors = []
        if (!this.$v.confirmPassword.$dirty) {
          this.messageSnackbar = 'Password must be required';
          return errors
        }
        !this.$v.confirmPassword.required && errors.push('Confirm password')
        !this.$v.confirmPassword.sameAsPassword && errors.push('Passwords do not match')
        return errors
      }
    },
    validations: {
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: { 
        required, 
        sameAsPassword: sameAs('password') 
      }
    },
    watch: {
    },
    methods: {
      async setPassword () {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        post(`${process.env.VUE_APP_SMT_API_URL}/user/password/change`, {
          i_UserID: await getUserID(),
          s_NewPassword: this.password,
          b_SendEmail: true
        })
        .then(response => {
          if(response.status === 401) {
            this.signOut();
            this.$smt.logout();
            return;
          }
          this.requestSent = true;
          this.responseMessage = 'Passwort changed';
          console.log('Worker response: ', response)
        })
        .catch(error => {
          console.log('Worker error: ', error)
        });
      },
      ...mapActions([
        'signOut',
      ])
    },
    mounted() {
      this.requestSent = false;
      this.responseMessage = '';
    },
  }
</script>
